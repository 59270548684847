import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

export default ({data}) => {
  const post = data.markdownRemark;
  return(
    <Layout metadata={data.site.siteMetadata}>
    <div>
      <h1 style={styles.postTitle}>{post.frontmatter.title}</h1>
      <div style={styles.postDate}>{post.frontmatter.date}</div>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </div>
    </Layout>
  )
}

const styles = {
  postTitle: {
    marginBottom: 0,
    marginTop: 30,
    fontSize: 38
  },
  postDate: {
    fontSize: 14,
    letterSpacing: 2,
    marginTop: 8,
    marginBottom: 20
  }
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;